.container
{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tabsTop{
  margin-top: 10px;
  margin-left: 20px;
}