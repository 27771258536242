.box_header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1px;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #DFE3E6;
  margin-bottom: 3px;
}

.box_center{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container
{
  display: flex;
  flex-direction: column;
  height: 50vh;
}