.Button-CspCert{
  width: 100%;
}
.csp-plugin-error{
  color:red;
}
.csp-plugin-success{
  color:#52c41a;
}
.csp-no-contains{
  letter-spacing: normal !important;
}