  .active
  {
      color: #fff;
      text-decoration: underline;
      font-weight: bold
  }

  .normal
  {
      color: #ccc;
      text-decoration: none;
      font-weight: normal
  }