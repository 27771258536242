.error-array{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}
.suggestions-container{
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}
.suggestions-container:hover{
  border-color: black;
}
.suggestions-input:focus-visible{
  outline-color: #1CA3B9;
}
.suggestions-container:focus-visible{
  outline: none;
}


.suggestions-input{
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: -webkit-fill-available;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 8.5px 14px;
}